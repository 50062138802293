import { Field } from 'formik';
import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { type StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';

import { TSelectOption } from '@people/ui/shared/interfaces';

type TFormSelectProps = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  isLoading?: boolean;
  showLabel?: boolean;
  onChange?: (e: TSelectOption) => void;
  selectOptions: TSelectOption[];
};

export const FormSelect: React.FC<TFormSelectProps> = ({
  disabled,
  label,
  name,
  showLabel = true,
  placeholder,
  selectOptions,
  onChange,
  isLoading
}) => {
  const selectStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      fontSize: '0.875rem',
      borderRadius: '0.375rem',
      minHeight: '34px'
    }),
    input: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    placeholder: (styles) => ({ ...styles, fontSize: '0.875rem' }),
    menu: (styles) => ({
      ...styles,
      fontSize: '0.875rem',
      padding: '2px',
      borderRadius: '0.375rem',
      zIndex: 4
    }),
    option: (styles) => ({
      ...styles,
      fontSize: '0.875rem',
      marginTop: '2px',
      marginBottom: '2px',
      borderRadius: '0.375rem'
    })
  };

  return (
    <Field name={name}>
      {({ field, form }: any) => (
        <FormControl
          gap={2}
          width="100%"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          isInvalid={form.errors[name] && form.touched[name]}>
          {showLabel && (
            <FormLabel fontWeight={600} fontSize="sm">
              {label}
            </FormLabel>
          )}
          <Select
            {...(field as StateManagerProps)}
            placeholder={placeholder}
            defaultValue={selectOptions[0]}
            value={field.value}
            options={selectOptions ?? []}
            isDisabled={disabled}
            onChange={(option) => {
              form.setFieldValue(name, option);
              if (onChange) {
                onChange(option as TSelectOption);
              }
            }}
            isLoading={isLoading}
            styles={selectStyles}
          />
          <FormErrorMessage color={form.errors[name] && 'red.500'}>
            {form.errors[name]}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
