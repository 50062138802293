import { Field } from 'formik';
import React from 'react';

import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

type TFormDateProps = {
  name: string;
  label: string;
  placeholder: string;
  showLabel?: boolean;
  onChange?: (e: string) => void;
};

export const FormDate: React.FC<TFormDateProps> = ({
  label,
  showLabel = true,
  name,
  placeholder,
  onChange
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => (
        <FormControl
          gap={2}
          width="100%"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          isInvalid={form.errors[name] && form.touched[name]}>
          {showLabel && (
            <FormLabel fontWeight={600} fontSize="sm">
              {label}
            </FormLabel>
          )}
          <Input
            {...field}
            px={4}
            py={2}
            min={1}
            type="date"
            fontSize="sm"
            borderWidth={1}
            borderRadius="md"
            placeholder={placeholder}
            onChange={(e) => {
              form.setFieldValue(name, e.target.value);
              if (onChange) {
                onChange(e.target.value);
              }
            }}
            borderColor={form.errors[name] ? 'red.500' : 'gray.300'}
          />
          <FormErrorMessage color={form.errors[name] && 'red.500'}>
            {form.errors[name]}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
