import React from 'react';
import { CgFileAdd } from 'react-icons/cg';
import { FiHelpCircle } from 'react-icons/fi';
import { HiPresentationChartLine } from 'react-icons/hi';
import { RiMenuUnfoldLine, RiNotification3Line } from 'react-icons/ri';
import { TbHomeMove } from 'react-icons/tb';
import { Link } from 'react-router-dom';

import { Box, Flex, Icon, Image, useBreakpointValue } from '@chakra-ui/react';

import { LogoDarkBackground } from '@people/ui/assets';
import { NavBarButton } from '@people/ui/shared/components/buttons';
import { NavBarLink } from '@people/ui/shared/components/links';
import { NavBarQuickAccessMenu, NavBarUserMenu } from '@people/ui/shared/components/menus';
import { AppPath } from '@people/ui/shared/enums';

export const NavBar: React.FC = () => {
  const isSmallScreens = useBreakpointValue({ base: true, md: false }, { ssr: false });

  return (
    <Flex
      w="100%"
      color="white"
      bg="#4C4E9A"
      height={10}
      flexShrink={0}
      paddingInline={2}
      paddingInlineEnd={4}
      position="relative"
      flexDirection="column">
      <Flex height={8} alignItems="center" marginTop={1}>
        <Flex alignItems="center" pr={{ md: 3 }} flex={1}>
          {isSmallScreens && (
            <Flex ml={1}>
              <NavBarButton>
                <Icon as={RiMenuUnfoldLine} w={6} h={6} color="inherit" />
              </NavBarButton>
            </Flex>
          )}
          <Flex
            pl={1}
            ml={2}
            alignItems="center"
            flexGrow={{ base: 1, md: 'unset' }}
            justifyContent={{ base: 'center', md: undefined }}>
            <Flex as={Link} to={AppPath.HOME}>
              <Image src={LogoDarkBackground} srcSet={LogoDarkBackground} maxH="1.5rem" />
            </Flex>
          </Flex>
          {!isSmallScreens && (
            <Flex gap={1} ml={1} alignItems="center" display={{ base: 'none', md: 'flex' }}>
              <NavBarLink path={AppPath.HOME}>
                <Icon as={TbHomeMove} w={5} h={5} color="inherit" /> Home
              </NavBarLink>
            </Flex>
          )}
        </Flex>
        {!isSmallScreens && <Flex width="26%" display={{ base: 'none', md: 'flex' }}></Flex>}
        {!isSmallScreens && (
          <Flex
            pl={3}
            flex={1}
            marginLeft="auto"
            alignItems="center"
            justifyContent="flex-end"
            display={{ base: 'none', md: 'flex' }}>
            <Flex
              as={Link}
              mr={1}
              gap={1}
              border={0}
              height={7}
              fontSize="sm"
              p="0 10px 0 8px"
              to={AppPath.PDG}
              borderRadius="md"
              overflow="hidden"
              alignItems="center"
              position="relative"
              fontWeight="semibold"
              justifyContent="center"
              bg="linear-gradient(-45deg,#ffc800,#ff02f0,#8930fd,#49ccf9)"
              _hover={{
                bg: '#ff02f0'
              }}>
              <Icon as={HiPresentationChartLine} w={5} h={5} color="inherit" /> PDG
            </Flex>

            <NavBarLink path={AppPath.SUBMIT_CLAIM}>
              <Icon as={CgFileAdd} w={5} h={5} color="inherit" /> Submit Claim
            </NavBarLink>
            <Box mx={1} height={4} width="1px" bg="rgba(255, 255, 255, 0.2)" />
          </Flex>
        )}

        {!isSmallScreens && (
          <Flex gap={1} ml={1} alignItems="center">
            <NavBarButton show label="Help">
              <Icon as={FiHelpCircle} w={5} h={5} color="inherit" />
            </NavBarButton>
            <NavBarButton show label="Notifications">
              <Icon as={RiNotification3Line} w={5} h={5} color="inherit" />
            </NavBarButton>
          </Flex>
        )}
        {!isSmallScreens && (
          <Flex gap={1} ml={1} alignItems="center" position="relative">
            <NavBarQuickAccessMenu />
          </Flex>
        )}
        <Box ml={2} position="relative">
          <NavBarUserMenu />
        </Box>
      </Flex>
    </Flex>
  );
};
