import { Field } from 'formik';
import React from 'react';

import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Fab } from '@mui/material';
import { common, red } from '@mui/material/colors';

type TFormNumberProps = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  value?: number | unknown;
  isProcessing?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  showLabel?: boolean;
  showProgressLoader?: boolean;
  onChange?: (value: string | number | unknown) => void;
};

export const FormNumber: React.FC<TFormNumberProps> = ({
  disabled,
  label,
  name,
  placeholder,
  value,
  isProcessing,
  isError,
  isSuccess,
  onChange,
  showLabel = true,
  showProgressLoader
}) => {
  const buttonSx = {
    ...(isSuccess && {
      bgcolor: 'rgb(132, 164, 75)',
      color: common.white,
      '&:hover': {
        bgcolor: 'rgb(115, 145, 62)',
        color: common.white
      },
      ...(isError && {
        bgcolor: red[500],
        color: common.white,
        '&:hover': {
          bgcolor: red[700],
          color: common.white
        }
      }),
      ...(isProcessing && {
        bgcolor: common.white,
        color: common.black,
        '&:hover': {
          bgcolor: common.white,
          color: common.black
        }
      })
    })
  };
  return (
    <Field name={name}>
      {({ field, form }: any) => (
        <FormControl
          gap={2}
          width="100%"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          isInvalid={form.errors[name] && form.touched[name]}>
          {showLabel && (
            <FormLabel fontWeight={600} fontSize="sm">
              {label}
            </FormLabel>
          )}
          <Flex flexDirection="row" gap={3} alignItems="center">
            <Input
              {...field}
              px={4}
              py={2}
              min={0}
              flexGrow={1}
              type="number"
              fontSize="sm"
              borderWidth={1}
              borderRadius="md"
              disabled={disabled}
              value={value || field.value}
              borderColor="gray.300"
              placeholder={placeholder}
              _invalid={{ borderColor: 'red.500' }}
              _disabled={{ bg: 'gray.100', cursor: 'not-allowed' }}
              onChange={(e) => {
                form.setFieldValue(name, e.target.value);
                if (onChange) {
                  onChange(e.target.value);
                }
              }}
            />
            {showProgressLoader && (
              <Box sx={{ position: 'relative' }}>
                <Fab
                  aria-label="http"
                  color="default"
                  sx={{ minHeight: 24, height: 24, width: 24, zIndex: 0, ...buttonSx }}>
                  {isSuccess ? (
                    <CheckIcon sx={{ width: 14, height: 14 }} />
                  ) : isError ? (
                    <CloseIcon sx={{ width: 14, height: 14 }} />
                  ) : (
                    <AccessTimeIcon sx={{ width: 14, height: 14 }} />
                  )}
                </Fab>
                {isProcessing && (
                  <CircularProgress
                    size={27}
                    sx={{
                      color: 'primary',
                      position: 'absolute',
                      top: -1,
                      left: -1,
                      zIndex: 0
                    }}
                  />
                )}
              </Box>
            )}
          </Flex>
          <FormErrorMessage color={form.errors[name] && 'red.500'}>
            {form.errors[name]}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
